(function($){
    $(document).ready(function() {

	 //    	$.wait = function( callback, seconds){
		//    return window.setTimeout( callback, seconds )
		// };

		// $.interval = function( callback, seconds){
		//    return window.setInterval( callback, seconds )
		// };
			
		var sfCounter 	= 0;
		var sfClass	= '.aside--facebook';
		var sfReady	= true;

		function sfAnim() {

			$('.sf-post').off('animate', sfAnim);
			
			sfReady = false;

			var $parent 	= $(this).parent();
			var $count 	= $parent.find('.sf-post').length;
			var $item 	= $parent.find('.sf-post').eq($count - 1).clone();

			var $random = Math.round(Math.random()  * (4 - 1) + 1);	
			var $class  = 'sf-hide-' + $random;	
			
			$parent.find('.sf-post').eq($count - 1).addClass($class);
			
			setTimeout( function(){ 
				$parent.find('.sf-post').eq($count - 1).remove();
				$parent.prepend($item);
				$('.sf-post').on('animate', sfAnim);
			}, 600);	
			
			setTimeout( function(){ 
				sfReady = true;
			}, 3000);
		};
				
		$('.sf-post').on('animate', sfAnim);

		setInterval(function() {
			if(sfReady) {
				$(sfClass).find('.sf-post').eq(0).trigger('animate');		
				sfClass = sfCounter % 2 === 0 ? '.aside--twitter' : '.aside--facebook' ;
				sfCounter++;	
			}
		}, 4000);

	});
    
}(jQuery));