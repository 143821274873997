(function($){
    $(document).ready(function() {

        $('.fontsize__icon').click(function (){
            var $fontsize = $(this).data('fontsize');

            $('body').removeClass('fontsize-small fontsize-medium fontsize-large').addClass($fontsize);

            $.cookie('fontsize', $fontsize, { expires: 7, path: '/' });
        });

    });
}(jQuery));