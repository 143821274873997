(function($){
	$(document).ready(function () {
		// for hamburger button only
		$('.navbar-toggle').on('click',function(){
			var e = $(this);
			e.attr('aria-expanded', e.attr('aria-expanded') == 'true');
			$(e.data('target')).toggleClass(e.data('toggle'));
			$('body').toggleClass('menu_open');
		});
		// end hamburger

		// hover effect on large screens
		$('.dropdown').bind('hover focus', function(evt) {
			var e = $(this);
			var w = $(window).width();

			if(w > 768) {
				switch (evt.type) {
					case 'mouseover':
					case 'mouseenter':
					case 'focus':
						e.addClass('open').children('a[role="button"]').attr('aria-expanded', true);
						break;
					case 'unfocus':
					case 'focusout':
					case 'mouseout':
					case 'mouseleave':
						e.removeClass('open').children('a[role="button"]').attr('aria-expanded', false);
						break;
				}
			}
		});
		//  end larger screens

		// dropdown menu behaviour if less than 768px width
		$('.dropdown').on(
				'click',
				function(evt) {
					var e = $(this);
					
					evt.stopPropagation();
					e.toggleClass('open');

					// close any open siblings 
					var notClicked = $(this).siblings('.open');
					notClicked.removeClass('open');
				}
		);

		// add .active to li not a 
		$('.dropdown-menu li a').on(
			'click',
				function(evt) {
					var e = $(this).parent('li');

					e.toggleClass('active');



				}
		);

		// $('.dropdown > .dropdown-menu > li > a').bind(
		// 		'click',
		// 		function(evt) {
		// 			var e = $('.dropdown');

		// 			e.addClass('open');		
		// 		}
		// );
		// $('.dropdown').bind(
		// 		'click',
		// 		function(evt) {

		// 			var e = $(this);

		// 			if(!e.hasClass('open')) {
		// 				evt.preventDefault();
		// 				evt.stopPropagation();
		// 				e.toggleClass('open').attr('aria-expanded', !e.hasClass('open'));
		// 			}
		// 		}
		// );

	});
}(jQuery));
