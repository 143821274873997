(function($){
	$(document).ready(function() {
		
		$('.yee-panel-heading').click(function(e) {
			$('.yee-panel-heading.open').removeClass("open");
			$(this).addClass("open");

			var a_id = $(this).find('a').attr('id');

			setTimeout(function(){
				window.location.hash = '#' + a_id;
			}, 500);
		});

		$(window).on('hashchange load', function(e){
			if(id = window.location.hash)
			{
				if( !$(id).closest('.yee-panel-heading').hasClass("open") )
				{
					$(id).click();
				}
			}
		});
	});
}(jQuery));



